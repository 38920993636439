// custom CSS styles - tobedeleted
// import "./src/styles/style.css"
// tailwindcss
import "./src/styles/global.css"

// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// Highlighting for code blocks
import "prismjs/themes/prism.css"
